<script>
  // const server = 'http://159.75.100.82:8081/xedu/api/';
  const server = 'https://api.afreeteacher.club/xedu/api/';  //API地址
  // const server = 'http://47.118.59.120:8081/xedu/api/';
  // const server = 'http://127.0.0.1:9090/xedu/api/';
  // const gpt ='http://g.afreeteacher.club:8081/xedu/api/';
  const gpt ='http://g.afreeteacher.club:8081/xedu/api/';
  const token  = localStorage.getItem('token');
  const version = localStorage.getItem('version');
  // const token="b915c6a366114f62b0425ebd3647b12d";
  const user = localStorage.getItem('user');
  const ads=localStorage.getItem('ads');
  const mUser= JSON.parse(user);
  // const domain="xedu.help911.club"; 
  const domain="www.afreeteacher.club"; //域名
  // const accept = "image/*,video/*,audio/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/msword,.doc,.docx,.mp4,.mp3,.ppt,.xls,.xlsx,.pdf" ;
  const accept="*";
  const lat="39.916527";   
  const lng="116.397128";
  const debug= false ;  // 调试状态  true 为调试模式 false 为非调试模式
  const preview="?ci-process=doc-preview&dstType=html&copyable=0";
  const pageview="?ci-process=doc-preview&page=";
  const redirect=localStorage.getItem('redirect');
  //const exts='.wmv,.rm,.mov,.mpeg,.mp4,.flv,.avi,.rmvb,.mpg,.mkv,.vob,.dat,.f4v,.mp3,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.gif,.jpg,.jpeg,.png';
  const exts='.wav, .wma, .wave,.wmv,.rm,.mov,.mpeg,.mp4,.flv,.avi,.rmvb,.mpg,.mkv,.vob,.dat,.f4v,.mp3,.gif,.jpg,.jpeg,.png,.pptx,.ppt,.pot,.potx,.pps,.ppsx,.dps,.dpt,.pptm,.potm,.ppsm,doc,.dot,.wps,.wpt,.docx,.dotx,.docm,.dotm,.mxls,.xlt,.et,.ett,.xlsx,.xltx,.csv,.xlsb,.xlsm,.xltm,.ets,.pdf';
  const extVideo='.wmv,.rm,.mov,.mpeg,.mp4,.flv,.avi,.rmvb,.mpg,.mkv,.vob,.dat,.f4v';
  const extImg='.gif,.jpg,.jpeg,.png';
  const extDoc='.pptx,.ppt,.pot,.potx,.pps,.ppsx,.dps,.dpt,.pptm,.potm,.ppsm,doc,.dot,.wps,.wpt,.docx,.dotx,.docm,.dotm,.mxls,.xlt,.et,.ett,.xlsx,.xltx,.csv,.xlsb,.xlsm,.xltm,.ets,.pdf';
  export default {
    server,
    token,
    accept,
    lat,
    lng,
    user,
    debug,
    mUser,
    domain,
    preview,
    redirect,
    ads,
    exts,
    extVideo,
    extImg,
    extDoc,
    pageview,
    version,
    gpt
  }
</script>
