import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
Vue.use(Router);
Vue.prototype.$axios = axios;
import Const from '@/Const';
Vue.use(Const);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) { return originalPush.call(this, location).catch(err => err) }


const routes = [
  {
    path: '*',
    redirect: '/default'
  },
  {
    name: 'home',
    path: '/home',
    component: resolve => require(['@/views/home'], resolve),
    meta: {
      title: '我的工作室'
    }
  },
  {
    name: 'brief',
    path: '/brief',
    component: resolve => require(['@/views/brief'], resolve),
    meta: {
      title: '简介'
    }
  },
  {
    name: 'about',
    path: '/brief/about',
    component: resolve => require(['@/views/brief/about'], resolve),
    meta: {
      title: '证书及成绩'
    }
  },
  {
    name: 'cert',
    path: '/brief/cert',
    component: resolve => require(['@/views/brief/cert/index'], resolve),
    meta: {
      title: '证书及成绩'
    }
  },
  {
    name: 'certDetail',
    path: '/brief/cert/detail',
    component: resolve => require(['@/views/brief/cert/detail'], resolve),
    meta: {
      title: '证书详情'
    }
  },
  {
    name: 'certAdd',
    path: '/brief/cert/add',
    component: resolve => require(['@/views/brief/cert/add'], resolve),
    meta: {
      title: '添加证书'
    }
  },
  {
    name: 'info',
    path: '/brief/info',
    component: resolve => require(['@/views/brief/info'], resolve),
    meta: {
      title: '个人信息'
    }
  },
  {
    name: 'experience',
    path: '/brief/experience',
    component: resolve => require(['@/views/brief/experience/index'], resolve),
    meta: {
      title: '经历'
    }
  },
  {
    name: 'experienceAdd',
    path: '/brief/experience/add',
    component: resolve => require(['@/views/brief/experience/add'], resolve),
    meta: {
      title: '添加经历'
    }
  },
  {
    name: 'display',
    path: '/brief/display',
    component: resolve => require(['@/views/brief/display/index'], resolve),
    meta: {
      title: '展示'
    }
  },
  {
    name: 'displayAdd',
    path: '/brief/display/add',
    component: resolve => require(['@/views/brief/display/add'], resolve),
    meta: {
      title: '添加展示'
    }
  },
  {
    name: 'entrance',
    path: '/brief/entrance',
    component: resolve => require(['@/views/brief/entrance'], resolve),
    meta: {
      title: '测试'
    }
  },
  {
    name: 'myCourse',
    path: '/brief/myCourse',
    component: resolve => require(['@/views/brief/myCourse'], resolve),
    meta: {
      title: '我的课程'
    }
  },
  {
    name: 'courseDetails',
    path: '/brief/courseDetails',
    component: resolve => require(['@/views/brief/courseDetails'], resolve),
    meta: {
      title: '课程详情'
    }
  },
  {
    name: 'addDisplay',
    path: '/brief/addDisplay',
    component: resolve => require(['@/views/brief/addDisplay'], resolve),
    meta: {
      title: '添加展示'
    }
  },
  {
    name: 'myCourseList',
    path: '/brief/myCourseList',
    component: resolve => require(['@/views/brief/myCourseList'], resolve),
    meta: {
      title: '添加课程'
    }
  },
  {
    name: 'personnelManage',
    path: '/brief/personnelManage',
    component: resolve => require(['@/views/brief/personnelManage'], resolve),
    meta: {
      title: '课程修改'
    }
  },
  {
    name: 'redemptionCode',
    path: '/brief/redemptionCode',
    component: resolve => require(['@/views/brief/redemptionCode'], resolve),
    meta: {
      title: '兑换码'
    }
  },
  {
    name: 'courseIntroduction',
    path: '/brief/courseIntroduction',
    component: resolve => require(['@/views/brief/courseIntroduction'], resolve),
    meta: {
      title: '介绍'
    }
  },
  {
    name: 'subcourse',
    path: '/brief/subcourse',
    component: resolve => require(['@/views/brief/subcourse'], resolve),
    meta: {
      title: '子课程'
    }
  },
  {
    name: 'metronome',
    path: '/brief/metronome',
    component: resolve => require(['@/views/brief/metronome'], resolve),
    meta: {
      title: '节拍器'
    }
  },
  {
    name: 'default',
    component: resolve => require(['@/views/home/default'], resolve),
    meta: {
      title: '教师工作室'
    }
  },
  {
    name: 'tool',
    component: resolve => require(['@/views/home/tool'], resolve),
    meta: {
      title: '教师工作室'
    }
  },
  {
    name: 'stop',
    component: resolve => require(['@/views/home/stop'], resolve),
    meta: {
      title: '网站停止运行'
    }
  },
  {
    name: 'study/more',
    path: '/study/more/:id',
    component: resolve => require(['@/views/user/msg'], resolve),
    meta: {
      title: '师生私信'
    }
  },
  {
    name: 'study/edit',
    component: resolve => require(['@/views/user/study-edit'], resolve),
    meta: {
      title: '发布'
    }
  },
  {
    name: 'study/edit2',
    path: '/study/edit2/:id',
    component: resolve => require(['@/views/user/study-edit2'], resolve),
    meta: {
      title: '编辑'
    }
  },
  {
    name: 'study/add',
    path: '/study/add/:id',
    component: resolve => require(['@/views/user/study-add'], resolve),
    meta: {
      title: '发布教学记录'
    }
  },
  {
    name: 'ad/add',
    path: '/ad/add',
    component: resolve => require(['@/views/user/ad-add'], resolve),
    meta: {
      title: '发布首页视频'
    }
  },
  {
    name: 'study/add2',
    path: '/study/add2/:id',
    component: resolve => require(['@/views/user/study-add2'], resolve),
    meta: {
      title: '发布教学记录'
    }
  },
  {
    name: 'video',
    component: resolve => require(['@/views/user/video'], resolve),
    meta: {
      title: '播放视频'
    }
  },
  {
    name: 'image',
    component: resolve => require(['@/views/user/image'], resolve),
    meta: {
      title: '查看图片'
    }
  },
  {
    name: 'pdf',
    component: resolve => require(['@/views/user/pdf'], resolve),
    meta: {
      title: '查看PDF'
    }
  },
  {
    name: 'content',
    component: resolve => require(['@/views/user/content'], resolve),
    meta: {
      title: '使用帮助'
    }
  },
  {
    name: 'file',
    component: resolve => require(['@/views/user/file'], resolve),
    meta: {
      title: '查看文件'
    }
  },
  {
    name: 'cropper',
    component: resolve => require(['@/views/user/cropper'], resolve),
    meta: {
      title: '图片裁剪'
    }
  },
  {
    name: 'teacher',
    component: resolve => require(['@/views/teacher/map'], resolve),
    meta: {
      title: '附近老师'
    }
  },
  {
    name: 'tmap',
    component: resolve => require(['@/views/teacher/map'], resolve),
    meta: {
      title: '附近老师'
    }
  },
  {
    name: 'user/intro',
    component: resolve => require(['@/views/user/intro'], resolve),
    meta: {
      title: '个人简介'
    }
  },
  {
    name: 'user/recruit',
    path: '/user/recruit/:id',
    component: resolve => require(['@/views/user/recruit'], resolve),
    meta: {
      title: '招生简章'
    }
  },
  {
    name: 'user/recruit-edit',
    path: '/user/recruit-edit/:id',
    component: resolve => require(['@/views/user/recruit-edit'], resolve),
    meta: {
      title: '招生简章编辑'
    }
  },
  {
    name: 'user',
    component: resolve => require(['@/views/user'], resolve),
    meta: {
      title: '个人中心'
    }
  },
  {
    name: 'detail',
    path: '/detail/:id',
    component: resolve => require(['@/views/user/detail'], resolve),
    meta: {
      title: '教学分享'
    }
  },
  {
    name: 'edit',
    component: resolve => require(['@/views/user/user-edit'], resolve),
    meta: {
      title: '个人信息'
    }
  },
  {
    name: 'login',
    path: '/login',
    component: resolve => require(['@/views/user/user-login'], resolve),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'location',
    component: resolve => require(['@/views/user/location'], resolve),
    meta: {
      title: '位置设置'
    }
  },
  {
    name: 'tlocation',
    component: resolve => require(['@/views/user/tlocation'], resolve),
    meta: {
      title: '位置设置'
    }
  },
  {
    name: 'map',
    component: resolve => require(['@/views/user/map'], resolve),
    meta: {
      title: '位置搜索'
    }
  },
  {
    name: 'message',
    component: resolve => require(['@/views/user/message'], resolve),
    meta: {
      title: '消息列表'
    }
  },
  {
    name: 'like',
    component: resolve => require(['@/views/user/like'], resolve),
    meta: {
      title: '关注'
    }
  },
  {
    name: 'collect',
    component: resolve => require(['@/views/user/collect'], resolve),
    meta: {
      title: '我的收藏'
    }
  },
  {
    name: 'help',
    component: resolve => require(['@/views/user/help'], resolve),
    meta: {
      title: '使用帮助'
    }
  },
  {
    name: 'contact',
    component: resolve => require(['@/views/user/contact'], resolve),
    meta: {
      title: '联系客服'
    }
  },
  {
    name: 'feedback',
    component: resolve => require(['@/views/user/feedback'], resolve),
    meta: {
      title: '意见反馈'
    }
  },
  {
    name: 'setting',
    component: resolve => require(['@/views/user/setting'], resolve),
    meta: {
      title: '设置'
    }
  },
  {
    name: 'mobile',
    component: resolve => require(['@/views/user/mobile'], resolve),
    meta: {
      title: '绑定手机'
    }
  },
  {
    name: 'bind',
    component: resolve => require(['@/views/user/bind'], resolve),
    meta: {
      title: '绑定手机'
    }
  },
  {
    name: 'vip',
    component: resolve => require(['@/views/user/vip'], resolve),
    meta: {
      title: 'VIP会员'
    }
  },
  {
    name: 'studio',
    component: resolve => require(['@/views/user/studio'], resolve),
    meta: {
      title: '我的主页二维码'
    }
  },
  {
    name: 'new',
    component: resolve => require(['@/views/user/new'], resolve),
    meta: {
      title: 'VIP体验'
    }
  },
  {
    name: 'renew',
    component: resolve => require(['@/views/user/renew'], resolve),
    meta: {
      title: 'VIP会员'
    }
  },
  {
    name: 'cate',
    component: resolve => require(['@/views/user/cate'], resolve),
    meta: {
      title: '选择科目'
    }
  },
  {
    name: 'balance',
    component: resolve => require(['@/views/user/balance'], resolve),
    meta: {
      title: '账户余额'
    }
  },
  {
    name: 'withdraw',
    component: resolve => require(['@/views/user/withdraw'], resolve),
    meta: {
      title: '提现'
    }
  },
  {
    name: 'recharge',
    component: resolve => require(['@/views/user/recharge'], resolve),
    meta: {
      title: '充值'
    }
  },
  {
    name: 'space',
    component: resolve => require(['@/views/user/space'], resolve),
    meta: {
      title: '我的空间'
    }
  },
  {
    name: 'gpt',
    component: resolve => require(['@/views/user/gpt'], resolve),
    meta: {
      title: 'AI机器人'
    }
  },
  {
    name: 'chat',
    component: resolve => require(['@/views/user/chat'], resolve),
    meta: {
      title: '对话充值'
    }
  },
  {
    name: 'user/bookroom',
    component: resolve => require(['@/views/user/bookroom'], resolve),
    meta: {
      title: '我的书房'
    }
  },
  {
    name: 'user/tool',
    path: '/user/tool',
    component: resolve => require(['@/views/user/tool'], resolve),
    meta: {
      title: '教学工具'
    }
  },
  {
    name: 'docs',
    path: '/docs/:id',
    component: resolve => require(['@/views/user/docs'], resolve),
    meta: {
      title: '教学工具'
    }
  },
  {
    name: 'download',
    path: '/download',
    component: resolve => require(['@/views/user/download'], resolve),
    meta: {
      title: '下载页面'
    }
  },
  {
    name: 'work',
    path: '/work/:id',
    component: resolve => require(['@/views/home/work'], resolve),
    meta: {
      title: '工作室'
    }
  },
  {
    name: 'user/student',
    path: '/user/student/:id',
    component: resolve => require(['@/views/user/student'], resolve),
    meta: {
      title: '教学分享'
    }
  },
  {
    name: 'user/student2',
    path: '/user/student2/:id',
    component: resolve => require(['@/views/user/student2'], resolve),
    meta: {
      title: '我的学习'
    }
  },
  {
    name: 'select/student',
    component: resolve => require(['@/views/user/student-select'], resolve),
    meta: {
      title: '教学分享'
    }
  },
  {
    name: 'select/student2',
    component: resolve => require(['@/views/user/student-select2'], resolve),
    meta: {
      title: '我的学习'
    }
  },
  {
    name: 'claim',
    path: '/claim/:id',
    component: resolve => require(['@/views/user/claim'], resolve),
    meta: {
      title: '申请认领'
    }
  },
  {
    name: 'success',
    path: '/success/:sid',
    component: resolve => require(['@/views/user/success'], resolve),
    meta: {
      title: '授权申请成功'
    }
  },
  {
    name: 'select/study',
    component: resolve => require(['@/views/user/study-select'], resolve),
    meta: {
      title: '我的学习'
    }
  },
  {
    name: 'user/tag',
    component: resolve => require(['@/views/user/tag'], resolve),
    meta: {
      title: '标签管理'
    }
  },
  {
    name: 'user/ad',
    component: resolve => require(['@/views/user/ad'], resolve),
    meta: {
      title: '轮播图管理'
    }
  },
  {
    name: 'user/cropper',
    component: resolve => require(['@/views/user/cropper'], resolve),
    meta: {
      title: '轮播图管理'
    }
  },
  {
    name: 'tool/tag',
    component: resolve => require(['@/views/user/tool-tag'], resolve),
    meta: {
      title: '标签管理'
    }
  },
  {
    name: 'study/detail',
    path: '/study/detail/:id',
    component: resolve => require(['@/views/user/study'], resolve),
    meta: {
      title: '学习记录'
    }
  },
  {
    name: 'doc/detail',
    path: '/doc/detail/:id',
    component: resolve => require(['@/views/user/doc'], resolve),
    meta: {
      title: '学习记录'
    }
  },
  {
    name: 'tag',
    component: resolve => require(['@/views/user/study-tag'], resolve),
    meta: {
      title: '标签管理'
    }
  },
  {
    name: 'student/edit',
    path: '/student/edit/:id',
    component: resolve => require(['@/views/user/student-edit'], resolve),
    meta: {
      title: '修改信息'
    }
  },
  {
    name: 'student/add',
    component: resolve => require(['@/views/user/student-add'], resolve),
    meta: {
      title: '修改信息'
    }
  },
  {
    name: 'student/add2',
    component: resolve => require(['@/views/user/student-add2'], resolve),
    meta: {
      title: '新建学习档案'
    }
  },
  {
    name: 'tool/add',
    component: resolve => require(['@/views/user/tool-add'], resolve),
    meta: {
      title: '新建文件夹'
    }
  },
  {
    name: 'tool/edit',
    path: '/tool/edit/:id',
    component: resolve => require(['@/views/user/tool-edit'], resolve),
    meta: {
      title: '修改教学工具条'
    }
  },
  {
    name: 'doc/add',
    path: '/doc/add/:id',
    component: resolve => require(['@/views/user/doc-add'], resolve),
    meta: {
      title: '新建教学工具'
    }
  },
  {
    name: 'doc/edit',
    path: '/doc/edit/:id',
    component: resolve => require(['@/views/user/doc-edit'], resolve),
    meta: {
      title: '编辑教学工具'
    }
  },
  {
    name: 'cart',
    component: resolve => require(['@/views/cart'], resolve),
    meta: {
      title: '购物车'
    }
  },
  {
    name: 'goods',
    component: resolve => require(['@/views/goods'], resolve),
    meta: {
      title: '商品详情'
    }
  },
  {
    name: 'share',
    path: '/share/:uid',
    component: resolve => require(['@/views/user/router'], resolve),
    meta: {
      title: '跳转'
    }
  },
  {
    name: 'share2',
    path: '/share2/:uid',
    component: resolve => require(['@/views/user/router2'], resolve),
    meta: {
      title: '跳转'
    }
  },
  {
    name: 'choice',
    path: '/choice/:sid',
    component: resolve => require(['@/views/user/choice'], resolve),
    meta: {
      title: '已认领学员'
    }
  },
  {
    name: 'choice2',
    path: '/choice2/:sid',
    component: resolve => require(['@/views/user/choice2'], resolve),
    meta: {
      title: '已认领学习'
    }
  },
  {
    name: 'editor',
    path: '/editor',
    component: resolve => require(['@/views/user/Editor.vue'], resolve),
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ mode: "history", routes });

function delCodeandstate(to) { //函数作用：去除url中的code和state
  let path = ""
  for (let i in to.query) {
    if (i != "code" && i != "state") {
      path = path + "&" + i + "=" + to.query[i]
    }
  }
  path = path == "" ? "" : path.substring(1, path.length)
  path = path == "" ? "" : "/?" + path
  return path;
}

router.beforeEach((to, from, next) => {
  let href = window.location.href // 获取当前地址
  let code = to.query.code, state = to.query.state
  if (!code && (Const.token == null || Const.token == "")) {
    localStorage.setItem("redirect", href);
  }
  let fullPath = to.fullPath
  if (to.fullPath.includes("code")) { //判断url中是否有code，踩坑1-页面反复跳转
    fullPath = delCodeandstate(to);
  } else {
    fullPath = 'default';
  }
  // debugger;
  // let redirect_uri = encodeURIComponent("https://www.afreeteacher.club/" + fullPath), appid = "wxf9306fe90d555537" //redirect_uri，授权登陆后的回调地址，需要进行encodeURIComponent处理
  let noLoginArr = ["/", "/download", "work"], isCur = false, token = localStorage.getItem("token") //noLoginArr，白名单，不需要授权登陆的页面
  let url = to.path.match(/\/(.*?)\//);
  if (url && url.length > 1) {
    url = url[1];
  } else {
    url = to.path;
  }
  isCur = noLoginArr.findIndex((item) => (item == url));
  if (isCur > -1) { //白名单内不做登录判断，直接next
    next()
  }
  else {
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) != 'micromessenger') {
      let img = document.createElement('img');
      img.style.width = '100%';
      img.style.marginTop = '60px';
      img.setAttribute('src', require("@/assets/imgs/limit.webp"));
      document.body.appendChild(img);
      return false;
    }
    if (code && state && !token) { //登陆之后获取到code，传到后台登录
      let data = {
        code: code
      }
      axios.post(Const.server + 'auth/code', data).then((response) => {
        if (response.data.code == 0 && response.data.data != null) {
          localStorage.setItem("token", response.data.data);
          Const.token = response.data.data;
          //  if(Const.token==null||Const.token==""){
          //    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
          //  }else{
          //    next()
          //  }
          next()
        }
      }).catch(function (error) {
        window.console.log(error);
      });
    } else {
      next();
    }
    // else if (token) { //已登录，有token，判断是否过期
    //      next();
    // } else { //未登录，没有token，去登录
    //     //去登录
    //     if(Const.debug){
    //       next();
    //     }else{
    //         window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
    //     }
    // }
  }
  // const title = to.meta && to.meta.title;
  // if (title) {
  //   document.title = title;
  // }
  // next();
});
// 修改注释
export {
  router
};
